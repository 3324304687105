<div class="innerPagesMain">
  <div class="homeMain">
      <app-home-top></app-home-top>
  

  <div class="innerPageBannerMain">
      <h3>About Jiveshwar Sali Reshimgathi </h3>
  </div>
  <div class="innerPageBodyMain">
      
      <div class="container">
          <div class="col-md-12">
            <div class="contentDivMain abutmain">
              <span class="aboutPara">
                Jiveshwar Sali Reshimgathi established by Mrs. Ruchita Kshirsagar in 2020 and since it's inception; has worked with passion in the field of match-making with a gentle and experienced touch. It is the first independant Mobile App / Website launched exploring world of matrimony especially ONLY for Sali Community. 

              </span>
              <span class="aboutPara">
                <a href="https://www.jiveshwarsalireshimgathi.com" target="_blank">jiveshwarsalireshimgathi.com</a> is one of the pioneer online platforms to provide a superior matchmaking experience to the entire Sali community and including it's 16+ sub-caste all over India and worldwide. Jiveshwar Sali Reshimgathi strongly believes that Marriage is not only a celebration of two souls getting together; but it's a Sanskar and Birth of new relations between two families. Jiveshwar Sali Reshimgathi provides you number of services through its online mobile mantrimony app which helps to find your perfect match. 
              </span>

              <span class="aboutPara">
                More to add to our portfolio, each profile registered with us goes through a manual screening and verification process before going live; we provide superior privacy controls for Free; and also verify contact information of members. Our purpose is to provide best quality services for our Brides and Grooms seeking their perfect life partner.
              </span>

              <span class="aboutPara">
                You can register for Free and search according to your specific criteria on age, height, martial status, education, location, profession and much more – on your computer tablet or mobile. So download, install, register free and search for your perfect life partner now on <a href="https://www.jiveshwarsalireshimgathi.com" target="_blank">jiveshwarsalireshimgathi.com.</a>
              </span>

            </div>
            <div class="contentDivMain abutmain">
              <h3>Mrs. Ruchita Kshirsagar</h3>
              <b>Director of Jiveshwar Sali Reshimgathi</b>
              <div class="aboutProDiv">
                <img src="../../assets/images/DSC_0924.JPG"/>
              </div>
              <span class="aboutPara">
                Mrs. Ruchita Kshirsagar is Director of Jiveshwar Sali Reshimgathi an Online Matrimony Service exclusively ONLY for Sali Community. She's is a Post Graduate with Masters in Computer Management from NMU, Jalgaon in 2007. 
              </span>
              <span class="aboutPara">
                In 2019, Covid 19 Pandemic gave her an evolutionary idea to develop and start with her Sali community specific Matrimony platform where bride's and groom's can virtually connect and share profiles with each other and with this beautiful thought Jiveshwar Sali Reshimgathi was inducted in July 2020. She is also from Sali Community and found her path to start her own unique way to address the problem of Manual Matrimonial searches.  
              </span>

              <span class="aboutPara">
              Her goal is to provide supreme quality matrimonial services to Sali Community. Ruchita has networked and counselled with many prospective brides, grooms, young married couple, and their parents and has made it her mission to simplify the process of finding the right match through this online platform.
            </span>
            </div>
          </div>


      </div>

     
  </div>

  <app-bottom></app-bottom>
</div>
</div>

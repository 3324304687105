import { Component, OnInit } from '@angular/core';
import { MymatchesService } from '../services/mymatches.service';
import {ShortlistedProfileIconService} from '../services/shortlisted-profile-icon.service'
import {PhotoRequestService} from '../services/photo-request.service'
import {SendInterestProfileService}  from '../services/send-interest-profile.service'
import {QuickSearchByIdService} from '../services/quick-search-by-id.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as $ from 'jquery';
import { LoginService } from '../services/login.service';
import {Router} from '@angular/router';
import { WebsiteConstants } from '.././global/website-constants';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service';

@Component({
  selector: 'app-my-matches',
  templateUrl: './my-matches.component.html',
  styleUrls: ['./my-matches.component.css']
})
export class MyMatchesComponent implements OnInit {
  profilePic : any; ProfileName : any; ProfileId : any; mymatchesresult : any;
  photopath : any; memberdetailsresult:any; logspinner:boolean; prodetailspinner:boolean;
  logshortlistmespinner:boolean; lognearmespinner:boolean;
  profilematchlocation : any; partnerpreference:any; matchprofilepic:any;cityname:any
  shortlistmedash:any; newMatchesdash:any; nearmeval:any; shortlistedval:any; myprofilevar:any;
  logshortlistedspinner:boolean; viewedmyprofilespinner : boolean; newmatchesspinner:boolean;
  mymatchscrollresult:any; myMessage:any
  fromLimit:any; toLimit:any
  fromLimitnext:any;toLimitnext;
  spinner: any = false;
  notEmptyPost: boolean =true;
  notscrolly: any=true;
  shorlistedIcon:any;
  UnshortlistedIcon:any;slectval:any
  MembName: string;InterestResponce:any; userIsPaid:any;quickSearchResponce:any;
  SiteUrl = WebsiteConstants.SiteUrl; 
  sitetitle = WebsiteConstants.SiteTitle; 
  masterUrl = WebsiteConstants.MasterUrl;
  AppDiscountBannerFlag:any=0;
  FlashBannerPath:any;
  FlashDisExpiry:any;
  FlashDisPack1:any;
  FlashDisPack2:any;
  FlashHeading:any;
  FlashWebBannerPath:any;

constructor(private service:MymatchesService, private route : Router, private memberShortlisted : ShortlistedProfileIconService, private  requestPhoto : PhotoRequestService, private sendInterest : SendInterestProfileService, private quickSearch : QuickSearchByIdService, private loginservice:LoginService,private memberservice : ViewmemberdetailsService) {
  this.slectval = 'Desc'
 }

ngOnInit(): void {
   this.photopath = this.masterUrl;
    this.profilePic = this.masterUrl+localStorage.getItem("UserPhotoPath");
    this.ProfileName = localStorage.getItem("UserFullName");
    this.ProfileId = localStorage.getItem("UserProfileID");
    this.cityname = localStorage.getItem("UserCityName");
    this.loginhistory();
    if(localStorage.getItem('updateAppInterestLimit')===null)
    {
      localStorage.setItem('updateAppInterestLimit',localStorage.getItem('UserSentIntCnt')); 
    }
      
    this.userIsPaid = localStorage.getItem('UserIsPaid');
    this.AppDiscountBannerFlag = localStorage.getItem('AppDiscountBannerFlag');
    
   
}

loginhistory()
{

    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('LoggedUserid',localStorage.getItem('UserMemberID'));
    this.loginservice.loginHistoryUrl(body,headers).subscribe(Response =>{

     if(Response['status']=="1")
     {
       this.myMessage="";
       this.myMachesfun();
     }
     else
     {
       this.mymatchesresult=Response['status'];
       this.myMessage = Response['message']
       this.logspinner = false;
     }
      
     
    },
      error => {
        Swal.fire('Oops...', "Something went wrong.Please contact support", 'info');
        this.logspinner = false;
      })
}
  


openMOdelPersonal()
{
  $('#upPhoto').css("display", "block",).toggleClass("in");
}

closeModelPersonal()
{
  $('#upPhoto').css("display", "none",).removeClass("in");
}



myMatchesPost = function()
  {
    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('FromLimit', '0');
    body.append('ToLimit', '40');
    body.append('OrderProfiles', 'DESC');
    localStorage.setItem('FromLimit','1');
    localStorage.setItem('ToLimit','40');
    localStorage.setItem('quryOrder','DESC');
    this.service.mymachesurl(body,headers).subscribe(Response =>{
      
      if(Response.data!==undefined)
      {
        this.mymatchesresult=Response.data;
        this.myMessage="";
      }
      else
      {
        this.mymatchesresult=Response.status;
        this.myMessage = Response.message
      }
       //this.logspinner = false;
       this.checkMembership();
    },
      error => {
        Swal.fire('Oops...', "Something went wrong.Please contact support", 'info');
        this.logspinner = false;
      })
}



bannerDashview()
  {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('CasteID', '3');

    this.loginservice.appbannerUrl(body,headers).subscribe(Result =>{
    if(Result['status']==1) 
    { 
          this.FlashBannerPath = this.masterUrl+Result['data'].FlashBannerPath;
          this.FlashDisExpiry = Result['data'].FlashDisExpiry;
          this.FlashDisPack1 = Result['data'].FlashDisPack1;
          this.FlashDisPack2 = Result['data'].FlashDisPack2;
          this.FlashHeading = Result['data'].FlashHeading;
          this.FlashWebBannerPath = this.masterUrl+Result['data'].FlashWebBannerPath;
    } 


  })

  }


onScroll(){
  
  if(this.notscrolly && this.notEmptyPost){
    this.spinner=true;
    this.notscrolly = false;
    this.loadnextpost();
  }
 
}

loadnextpost(){
  let headers = new Headers({ 'Content-Type': 'application/json' });
  let body = new FormData();

  this.fromLimit = localStorage.getItem('FromLimit')
  this.toLimit = localStorage.getItem('ToLimit')

  this.fromLimitnext = parseInt(this.fromLimit)+ 40 ;
  this.toLimitnext = parseInt(this.toLimit) + 40;
  
  localStorage.setItem('FromLimit',this.fromLimitnext );
  localStorage.setItem('ToLimit',this.toLimitnext);

  body.append('MemberID',localStorage.getItem('UserMemberID'));
  body.append('FromLimit', this.fromLimitnext);
  body.append('ToLimit', this.toLimitnext);

  let quryOrder = localStorage.getItem('quryOrder');
  body.append('OrderProfiles', quryOrder);

  
  this.service.mymachesurl(body,headers).subscribe(Res =>{

  const newPost = Res['data'];
  this.spinner = false;
 if(newPost.length === 0)
 {
   this.notEmptyPost = false
 }
   this.mymatchesresult = this.mymatchesresult.concat(newPost)
   this.notscrolly = true
  })
}

selectOption(value: string)
{
    this.logspinner = true;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();
    body.append('MemberID',localStorage.getItem('UserMemberID'));
    body.append('FromLimit', '0');
    body.append('ToLimit', '40');
    body.append('OrderProfiles', value);
    localStorage.setItem('FromLimit','1');
    localStorage.setItem('ToLimit','40');
    localStorage.setItem('quryOrder',value);
    this.service.mymachesurl(body,headers).subscribe(Response =>{
      
      if(Response['data']!==undefined)
      {
        this.mymatchesresult=Response['data'];
        this.myMessage="";
      }
      else
      {
        this.mymatchesresult=Response['status'];
        this.myMessage = Response['message']
      }
    this.logspinner = false;
    })
}

redirectuser()
{
  let link = ['/update-profile'];
  this.route.navigate(link);
}

myMachesfun()
{
  let alertbox = localStorage.getItem('photoModelbox')
  this.myMatchesPost()

  if(localStorage.getItem('UserIsPhotoUpload')=="0" && alertbox==null)
  {
    this.openMOdelPersonal();
    localStorage.setItem('photoModelbox','shown')
  }
  
  $(document).ready(function()
  {
    $('#mymatchid').addClass('selected');
    $('#mynewmatchid').removeClass('selected');
    $('#nearmeid').removeClass('selected');
    $('#shortlistedid').removeClass('selected');
    $('#viewmyprofileid').removeClass('selected');
    $('#shortlistedmeid').removeClass('selected');

     })
    
  }


  checkMembership()
  {

  let headers = new Headers({'Content-Type' : 'Application/json'})
  let body = new FormData

   body.append('VwName', localStorage.getItem('UsercasteView'));
   body.append('MemberID',localStorage.getItem('UserMemberID'));
   body.append('MembIsPaid', '1');
   body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 

   return this.memberservice.memberdetails(body,headers).subscribe(Response =>{



      // let tempispaid = localStorage.getItem("UserIsPaid");
      // let headers = new Headers({ 'Content-Type': 'application/json' });
      // let body = new FormData();
      // body.append('MemUserName', localStorage.getItem("webusername"));
      // body.append('MemPwd', localStorage.getItem("webpassword"));
      // this.loginservice.loginUrl(body,headers).subscribe(Response =>{
      if(Response['status']==1) 
      {

        localStorage.setItem("userid", Response['data'].MemberID);
        localStorage.setItem("userprofileid", Response['data'].MemberProfileID);
        // localStorage.setItem("MemberOTPVerified", Response['data'].MemberOTPVerified);
        localStorage.setItem('UserMemberID',Response['data'].MemberID);
        localStorage.setItem('UserProfileID',Response['data'].MemberProfileID);
        localStorage.setItem('UserCasteCode',Response['data'].MemberCasteCode);
        localStorage.setItem('UserFullName',Response['data'].MemberFullName);
        localStorage.setItem('UserGender',Response['data'].MemberGender);
        localStorage.setItem('UserHeight',Response['data'].MemberHeight);
        localStorage.setItem('UserMartialStatus',Response['data'].MemberMartialStatus);
        localStorage.setItem('UserCasteID',Response['data'].MemberCasteID);
        localStorage.setItem('UserCasteName',Response['data'].MemberCasteName);
        localStorage.setItem('UserIsApprove',Response['data'].MemberIsApprove);
        localStorage.setItem('UserIsPaid',Response['data'].MemberIsPaid);
        localStorage.setItem('UserRegistrationDt',Response['data'].MemberRegistrationDt);
        localStorage.setItem('UserAge',Response['data'].MemberAge);
        localStorage.setItem('UserDOB',Response['data'].MemberDOB);
        localStorage.setItem('UserIsPhotoUpload',Response['data'].MemberIsPhotoUpload);
        localStorage.setItem('UserPhotoPath',Response['data'].MemberPhotoPath);
        localStorage.setItem('UserCountryID',Response['data'].MemberCountryID);
        localStorage.setItem('UserCountryName',Response['data'].MemberCountryName);
        localStorage.setItem('UserStateID',Response['data'].MemberStateID);
        localStorage.setItem('UserStateName',Response['data'].MemberStateName);
        localStorage.setItem('UserCityID',Response['data'].MemberCityID);
        localStorage.setItem('UserCityName',Response['data'].MemberCityName);
        localStorage.setItem('UserEduID',Response['data'].MemberEduID);
        localStorage.setItem('UserHeightsEducation',Response['data'].MemberHeightsEducation);
        localStorage.setItem('UserEduGroupID',Response['data'].MemberEduGroupID);
        localStorage.setItem('UserEductionGroupName',Response['data'].MemberEductionGroupName);
        localStorage.setItem('UserEmployedIn',Response['data'].MemberEmployedIn);
        localStorage.setItem('UserOccGroupID',Response['data'].MemberOccGroupID);
        localStorage.setItem('UserOccupGroupName',Response['data'].MemberOccupGroupName);
        localStorage.setItem('UserOccID',Response['data'].MemberOccID);
        localStorage.setItem('UserOccupation',Response['data'].MemberOccupation);
        localStorage.setItem('UserJobCountryName',Response['data'].MemberJobCountryName);
        localStorage.setItem('UserJobCity',Response['data'].MemberJobCity);
        localStorage.setItem('UserFamilyStatus',Response['data'].MemberFamilyStatus);
        localStorage.setItem('UserFamilyType',Response['data'].MemberFamilyType);
        localStorage.setItem('UserFamilyValue',Response['data'].MemberFamilyValue);
        localStorage.setItem('UsercasteView',Response['data'].MembercasteView);
        localStorage.setItem('UserSentIntCnt',Response['data'].MemberSentIntCnt);
        localStorage.setItem('UserIsPhotoUpload',Response['data'].MemberIsPhotoUpload);
        this.logspinner = false;
        this.bannerDashview();
    //     if(tempispaid != Response['data'].MemberIsPaid)
    //     {
    //         let link = ['/my-matches'];
    //         this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //         this.route.navigate(link);
       
    // });
    //     }
          
       }
      else 
      {
          this.logspinner = false;
      }

      // },
      // error => {
    
      //   this.logspinner = false;
      // })

    })
  }



  memberInterestlist(row)
  {
    var userapplimit = localStorage.getItem('UserAppInterestLimit');
    var userapplimitval = Number(userapplimit);
    
    if(localStorage.getItem('UserIsPaid')=='0' && userapplimitval >0)
    {
      var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
      var usersentintcntval = Number(usersentintcnt);

        if(usersentintcntval<userapplimitval)
        {
            Swal.fire({
            title: 'Are you sure?',
            text: 'As a free member you can send only '+userapplimitval+' interest to liked profile. Are you sure you want to send interest?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.value) {
            let profilename = row.MemberName+', '+row.MemberProfileID;
            let messageforsuccess = 'Your request has been sent successfully to '+profilename;
            Swal.fire({
              title : 'Sent', 
              text: messageforsuccess, 
              icon : 'success',
              showConfirmButton : false,
              timer : 1500});
            
            $('#sentrunInterst'+row.MemberID).hide();
            $('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
            $('#sentshowInterst'+row.MemberID).addClass('likeIntro');

          var usersentintcnt = localStorage.getItem('updateAppInterestLimit');
          var usersentintcntval = parseInt(usersentintcnt)+1;
          
          localStorage.setItem('updateAppInterestLimit',usersentintcntval.toString());

          let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('MemberID',localStorage.getItem('UserMemberID'))
          body.append('MembInterestID', row.MemberID)
          body.append('MembIntFlag','Interest')
          body.append('MembFreeOrPaid','0')
          return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
            
          })
            
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            
            }
          })


        }
        else
        {

            let message = 'As a free member, you have already sent '+userapplimitval+' interest to liked profile.';
            Swal.fire({
              title : 'Request didn\'t send', 
              text: message, 
              icon : 'error',
              showConfirmButton : true
              //timer : 1500
            });
             
        }
    }
    else
    {
      let profilename = row.MemberName+', '+row.MemberProfileID;
            let messageforsuccess = 'Interest sent successfully to'+profilename;
            Swal.fire({
              title : 'Interest Sent', 
              text: messageforsuccess, 
              icon : 'success',
              showConfirmButton : false,
              timer : 1500});


      $('#sentrunInterst'+row.MemberID).hide();
      $('#sentshowInterst'+row.MemberID).removeClass('hideHeart');
      $('#sentshowInterst'+row.MemberID).addClass('likeIntro');
      
      

      //alert(row.MemberID)
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemberID',localStorage.getItem('UserMemberID'))
      body.append('MembInterestID', row.MemberID)
      body.append('MembIntFlag','Interest')
      body.append('MembFreeOrPaid','1');

      return this.sendInterest.sendInterestPhotoPost(body,headers).subscribe(interestRes =>{
        
      })


    }


    
  }

  membershortlist(row)
  {
    
        var hiddenfield = $("#hiddenshort"+row.MemberID).val();

        if(hiddenfield==0)
        {
            let profilename = row.MemberName+', '+row.MemberProfileID;
            let messageforsuccess = 'You have shortlisted Profile '+profilename;
            Swal.fire({
              title : 'Shortlisted', 
              text: messageforsuccess, 
              icon : 'success',
              showConfirmButton : false,
              timer : 1500});

           $("#hiddenshort"+row.MemberID).val(1);
           $('#shortListID'+row.MemberID).addClass('shortLis');

            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MemberID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID)

            return this.memberShortlisted.memberShortlistedPost(body,headers).subscribe(shortlistedResponce =>{

            this.shorlistedIcon = shortlistedResponce;
            //Swal.fire('Shortlisted', messageforsuccess, 'success');
            })
        }
        else
        {
          let profilename = row.MemberName+', '+row.MemberProfileID;
          let messageforsuccess = 'You have Un-shortlisted Profile '+profilename;
          
          Swal.fire({
            title : 'Un-Shortlisted', 
            text: messageforsuccess, 
            icon : 'success',
            showConfirmButton : false,
            timer : 1500});

            $("#hiddenshort"+row.MemberID).val(0);
          $('#shortListID'+row.MemberID).removeClass('shortLis');

          
            let headers = new Headers({ 'Content-Type': 'application/json' });
            let body = new FormData();

            body.append('MembShortlistByID',localStorage.getItem('UserMemberID'))
            body.append('MembShortlistToID',row.MemberID)

            return this.memberShortlisted.memberUnShortlistedPost(body,headers).subscribe(unShortlistedResponce =>{
            
            })

        }
    
  }

  photoRequestDash(element,row)
  {
    element.textContent = 'Photo Request Sent';
    element.disabled = true;
      
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let body = new FormData();

    body.append('MemberID',localStorage.getItem('UserMemberID'))
    body.append('MemberName',localStorage.getItem('UserFullName'))
    body.append('MembInterestID', row.MemberID)
    body.append('MembName', row.MemberName)
    body.append('MembIntFlag','photo')
    body.append('MembFreeOrPaid', localStorage.getItem('UserIsPaid'))

    return this.requestPhoto.photoRequestPost(body,headers).subscribe(photorequest =>{
      let msgVal = 'You have sent request for photo to '+row.MemberName;
      //Swal.fire('Success' , msgVal , 'success');
      Swal.fire({
        title : 'Success', 
        text: msgVal, 
        icon : 'success',
        showConfirmButton : false,
        timer : 1500
      });
    })
  }


  memberdetailspost(row)
  {
    localStorage.setItem('viewUser',row.MemberID)
    let link = ['/member-details'];
    this.route.navigate(link);
  }

  
openMOdel(row,masterUrl)
{
  $('#callModal').css("display", "block");
  $("#callImgDivs").html('<img src="'+ masterUrl+row.MemberPhotoPath + '" style="width: 95px" />');

  $('#popupName').text('Contact '+row.MemberName+' on');
  if(localStorage.getItem('UserIsPaid')=='0')
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;XXXXXXXXXX');
    $('#popupupgrads').html('<p>Upgrade now to view her phone number</p><button class="callUpgraBT"><a href="/upgrade-account">Upgrade now</a></button>');
  }
  else
  {
    $('#popupContact').html('<i class="fa fa-phone"></i> &nbsp;'+row.MemberContactNo);
    $('#popupupgrads').html('');
  } 
  
}

closePopup()
{
  $('#callModal').css("display", "none")
}

}


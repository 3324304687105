import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegistrationComponent} from './registration/registration.component';
import { DashboardComponent} from './dashboard/dashboard.component';
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import {ConfirmotpComponent} from './confirmotp/confirmotp.component';
import { AuthGuard } from './auth/auth.guard';
import {ScrollComponent} from './scroll/scroll.component'
import { MyMatchesComponent } from './my-matches/my-matches.component';
import {MyDashboardComponent} from './my-dashboard/my-dashboard.component'
import { NewMatchesComponent } from './new-matches/new-matches.component';
import { NearMeComponent } from './near-me/near-me.component';
import {ShortlistedComponent} from './shortlisted/shortlisted.component';
import {ViewedMyProfileComponent} from './viewed-my-profile/viewed-my-profile.component';
import {ShortlistedMeComponent } from './shortlisted-me/shortlisted-me.component';
import {MemberDetailsComponent} from './member-details/member-details.component';
import {QuickSearchComponent} from './quick-search-by-id/quick-search.component';
import {MyDiscoverMatchesComponent} from './my-discover-matches/my-discover-matches.component'
import {UpdateProfileComponent} from './update-profile/update-profile.component'
import {ProfilePreferredLocationComponent} from './profile-preferred-location/profile-preferred-location.component'
import {ProfilePreferredProfesssionComponent} from './profile-preferred-professsion/profile-preferred-professsion.component'
import {ProfilePreferredEducationComponent} from './profile-preferred-education/profile-preferred-education.component'
import {EditPreferenceComponent} from './edit-preference/edit-preference.component'
import {SendInterestRequestComponent} from './send-interest-request/send-interest-request.component'
import{UpgradeAccountComponent} from './upgrade-account/upgrade-account.component';
import { ShowNotificationComponent } from './show-notification/show-notification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {PhotoGalleryComponent} from './photo-gallery/photo-gallery.component';
import {HelpScreenComponent} from './help-screen/help-screen.component';
import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { AdvanceDetailComponent } from './advance-detail/advance-detail.component';
import { SucessPaymentComponent } from './sucess-payment/sucess-payment.component';
import { FailPaymentComponent } from './fail-payment/fail-payment.component';
import { CancelPaymentComponent } from './cancel-payment/cancel-payment.component';
import {ViewSuccessStoryComponent} from './view-success-story/view-success-story.component'
import { PaymentNewComponent } from './payment-new/payment-new.component'
import { SuccessRedirectComponent } from './success-redirect/success-redirect.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import { TermsUseComponent } from './terms-use/terms-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';


const routes: Routes = [
  {
    path:'',component:HomeComponent
  },
  {
  path:'login',component:LoginComponent
  },
  {
  path:'plans',component:PaymentNewComponent
  },
  {
    path:'registration',component:RegistrationComponent
  },
  {
    path:'terms-use',component:TermsUseComponent
  },
  {
    path:'privacy-policy',component:PrivacyPolicyComponent
  },
  {
  path:'confirm-otp',component:ConfirmotpComponent
  },
  {
  path:'search-details', canActivate:[AuthGuard],component:AdvanceDetailComponent
  },
  {
    path:'success-stories',canActivate:[AuthGuard],component:SuccessStoriesComponent
  },
  {
    path:'view-success-stories',canActivate:[AuthGuard],component:ViewSuccessStoryComponent
  },
  {
  path:'advance-search', canActivate:[AuthGuard],component:AdvanceSearchComponent
  },
  {
  path:'dashboard', canActivate:[AuthGuard],component:DashboardComponent
  },
  {
    path:'scroll', canActivate:[AuthGuard], component:ScrollComponent
  },
  {
    path:'my-dashboard',canActivate:[AuthGuard], component:MyDashboardComponent
  },
  {
    path:'my-matches', canActivate:[AuthGuard], component:MyMatchesComponent
  },
  {
    path:'new-matches', canActivate:[AuthGuard], component:NewMatchesComponent
  },
  {
    path:'near-me', canActivate:[AuthGuard], component:NearMeComponent
  },
  {
    path:'shortlisted', canActivate:[AuthGuard], component:ShortlistedComponent
  },
  {
    path:'viewed-my-profile', canActivate:[AuthGuard], component:ViewedMyProfileComponent
  },
  {
    path:'shortlisted-me', canActivate:[AuthGuard], component:ShortlistedMeComponent
  },
  {
    path:'member-details', canActivate:[AuthGuard], component:MemberDetailsComponent
  },
  {
    path:'quicksearch', canActivate:[AuthGuard], component:QuickSearchComponent
  },
  {
    path:'my-discover-matches', canActivate:[AuthGuard], component:MyDiscoverMatchesComponent
  },
  {
    path:'ViewProfilePreferredLocation', canActivate:[AuthGuard], component:ProfilePreferredLocationComponent
  },
  {
    path:'ViewProfilePreferredProfession', canActivate:[AuthGuard], component:ProfilePreferredProfesssionComponent
  },
  {
    path:'ViewProfilePreferredEducation', canActivate:[AuthGuard], component:ProfilePreferredEducationComponent
  },
  {
    path:'update-profile', canActivate:[AuthGuard], component:UpdateProfileComponent
  },
  {
    path:'edit-preference', canActivate:[AuthGuard], component:EditPreferenceComponent
  },
  {
    path:'send-interest-request', canActivate:[AuthGuard], component:SendInterestRequestComponent
  },
  {
    path:'upgrade-account', canActivate:[AuthGuard], component:UpgradeAccountComponent
  },
  {
    path:'show-notification', canActivate:[AuthGuard], component:ShowNotificationComponent
  },
  {
    path:'change-password', canActivate:[AuthGuard], component:ChangePasswordComponent
  },
  {
    path:'user-gallery', canActivate:[AuthGuard], component:PhotoGalleryComponent
  },
  {
    path:'help-screen', canActivate:[AuthGuard], component:HelpScreenComponent
  },
  {
    path:'success-payment', canActivate:[AuthGuard], component:SucessPaymentComponent
  },
  {
    path:'successful-payment', canActivate:[AuthGuard], component:SuccessRedirectComponent
  },
  {
    path:'fail-payment', canActivate:[AuthGuard], component:FailPaymentComponent
  },
  {
    path:'cancel-payment', canActivate:[AuthGuard], component:CancelPaymentComponent
  },
  {
  path:'about-us',component:AboutUsComponent
  },
  {
  path:'contact-us',component:ContactUsComponent
  },
  { 
    path:'not-found',component:PagenotfoundComponent
  },
  { 
    path:'**', redirectTo: 'not-found'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

<div class="innerPagesMain">
    <div class="homeMain">
        <app-home-top></app-home-top>
    
  
    <div class="innerPageBannerMain">
        <h3>Terms Of Use</h3>
    </div>
    <div class="innerPageBodyMain">
        
        <div class="container">
            <div class="col-md-12">
              <div class="contentDivMain termsUseMain">
                <!-- <h3>Kailas Gaikwad</h3> -->
                <h1>Terms and Conditions</h1>
                <span class="aboutPara">
                    This document is an electronic record in terms of the Information Technology Act, 2000 and rules there under pertaining to electronic records as applicable and amended. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and the terms and conditions for access or usage of Jiveshwar Sali Reshimgathi ("Mobile app").
                </span>

                <h2 class="subHedTerms">DEAR MEMBER</h2>
                <span class="aboutPara">
                    PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY BEFORE USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND CONDITIONS PLEASE READ TERMS AND CONDITIONS MENTIONED BELOW BEFORE REGISTRATION.
                </span>

                <h2 class="subHedTerms">SCOPE</h2>
                <span class="aboutPara">
                    <ul>
                        <li>
                            You understand and acknowledge that Jiveshwar Sali Reshimgathi (JR) acts as an "Intermediary" as defined under clause (1) sub-clause (w) of Section 2 of the Information Technology Act, 2000 which owns, retains and has complete rights in JR and the JR Website /App/ JR Service.
                        </li>
                        <li>
                            JR acts as a platform to enable any user to themselves register on it (by filling the mandatory fields and optional fields, if any) to voluntarily search for profile(s) from the database of JR’s already registered users, for seeking prospective lawful matrimonial alliances for themselves. JR may also be able to assist you to register your profile on app, however, you must have a valid mobile phone number or an email id. JR Members are provided with free/paid access for searching profiles from the database of JR, as per the partner preference set by you (on the Mobile app.) and you can send interest and shortlist the profiles.
                        </li>
                    </ul>
                </span>

                <h2 class="subHedTerms">JIVESHWARSALIRESHIMGATHI MEMBERSHIP AND RIGHTS OF ADMISSION IS RESERVED SOLELY FOR :</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>Indian Nationals & Citizens.</li>
                        <li>Persons of Indian Origin (PIO).</li>
                        <li>Non Resident Indians (NRI).</li>
                        <li>Persons of Indian Descent or Indian Heritage</li>
                        <li>Persons who intend to marry persons of Indian Origin.</li>
                    </ul>
                </span>

                <h2 class="subHedTerms">FURTHER IN CAPACITY AS JIVESHWARSALIRESHIMGATHI YOU CONFIRM THAT YOU ARE :</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>18 years or above (if you are a woman) or 21 years or above (if you are a man);</li>
                        <li>Legally competent to marry as per the law applicable to you (however you may register on our website by stating "Awaiting Divorce"), and</li>
                        <li>Not prohibited or prevented by any applicable law for the time being in force from entering into a valid marriage.</li>
                    </ul>
                </span>

                <h2 class="subHedTerms">REGISTRATION</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            A. We expect that you would complete the online registration process with fairness and honesty in furnishing true, accurate, current, complete information and with providing recent photos of you, which will help you to meet your parameters (expectation) .We expect you to read carefully the relevant column before updating the details or selecting the option available or uploading your profile photo. In order to serve you better if JR requires additional details you agree to provide it. You may call us to our phone number to assist you in getting your profile registered on our Mobile App. Further you may fill in your email id and phone number in any of our registration campaigns appearing in various websites as part of our advertisements, basis which you may receive call from our Customer Service Center and assist you in getting your profile registered on our website/App.
                        </li>
                        <li>
                            B. If at any point of time JR comes to know or is so informed by third party or has reasons to believe that any information provided by you for registration (including photos) or otherwise is found to be untrue, inaccurate, or incomplete,JR shall have full right to deactivate or terminate (without any notice) your JR membership and forfeit any amount paid by you towards JR membership fee and refuse to provide JR service to you thereafter.
                        </li>
                        <li>C. JR also reserves the right to indicate and display the message at the time of registration on Website/App, In the case of your contact details / email id is already in JR database</li>
                        <li>
                            D. Duplicate profiles of the same person is not allowed in JR Website/App. JR will have full right to deactivate or terminate (without any notice) such duplicate profile.
                        </li>
                        <li>
                            E. You acknowledge and confirm that your registration with JR and the usage of JR services is with the bonafide intention of marriage and not otherwise. JR Membership is restricted strictly to the registered JR individual member only.
                        </li>
                    </ul>
                </span>

                <h2 class="subHedTerms">WHAT INFORMATION DOES JIVESHWARSALIRESHIMGATHI COLLECT FROM YOU?</h2>
                <span class="aboutPara">
                    We gather information from members and guests who apply for the various services our site offers. It includes, but may not be limited to, email address, first name, last name, a user-specified password, e-mail Id, mailing address, and telephone number. Jiveshwar Sali Reshimgathi shall act on the basis of the information that is being provided by you, believing the same to be true and accurate. We assume the details provided are genuine and under your express consent even if they are being submitted by your family, friends, relatives or a third party on your behalf. By submitting your details, you agree to share your information with other members on Jiveshwar Sali Reshimgathi. This site is under no obligation to verify the accuracy or genuineness of the information submitted by you. Any complaints arising in this regard will be your responsibility.
                </span>

                <h2 class="subHedTerms">WITH WHOM THE SITE SHARES THE INFORMATION IT COLLECTS.</h2>
                <span class="aboutPara">
                    The information collected from our clients is shared only with members of Jiveshwar Sali Reshimgathi or members. Any information you give us is held with the utmost care and security. We are also bound to cooperate fully should a situation arise where we are required by law or legal process to provide information about a customer.
                </span>

                <h2 class="subHedTerms">DO ALL VISITORS HAVE TO PAY?</h2>
                <span class="aboutPara">
                    NO, All visitors to our app/website may browse the site, search the ads and view any articles or features our site has to offer without entering any personal information or paying money. But obvious if you have to see profile details you must register yourself and pay the applicable charges.
                </span>

                <h2 class="subHedTerms">HOW JIVESHWARSALIRESHIMGATHI TEAM CAN CONTACT YOU?</h2>
                <span class="aboutPara">
                    The Jiveshwar Sali Reshimgathi team will contact you from time to time to apprise you on various features and functionalities of the website OR mobile application. They will contact you for either "Service" or "Sales" through a variety of mediums like Email, SMS, Whatsapp, Voice IVR, USSD, WAP messages, Telephonic Calls etc. The calls made to you will be from a regular Mobile or Landline number. Notice: We may change this Privacy Policy from time to time based on your comments or as a result of a change of policy in our company. If you have any questions regarding our Privacy Statement, please write us on jiveshwarsalireshimgathi@gmail.com or we have help page where you will see contact number and email id.
                </span>

                <h2 class="subHedTerms">ACCOUNT SECURITY</h2>
                <span class="aboutPara">
                    You are responsible for safeguarding the confidentiality of your JR login credentials such as your user id, password, OTP, etc., and for restricting access to your computer/mobile to prevent unauthorized access to your account. We, as a Intermediary do not ask for Password and you are cautioned not to share your password to any persons.. You agree to accept responsibility for all activities that occur under your account.
                </span>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>Never share your Jiveshwar Sali Reshimgathi account information (user id, password) with anyone</li>
                        <li>Never share your financial details like bank account number, online banking credentials, credit card details etc.</li>
                        <li>
                            Do a thorough background research about the person you wish to proceed ahead.
                        </li>
                        <li>
                            Beware of fake sites. Ensure that you access Jiveshwar Sali Reshimgathi Mobile app and pay money to authorized representatives from Jiveshwar Sali Reshimgathi only.
                        </li>
                        <li>
                            Notify Jiveshwar Sali Reshimgathi customer support team immediately on jiveshwarsalireshimgathi@gmail.com if you suspect something is not genuine about any profile or a representative reaching out to you.
                        </li>
                    </ul>
                </span>


                <h2 class="subHedTerms">ROLE AND RESPONSIBILITY OF JR</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            JR reproduces your details once you register on our website /App on "as is as available" basis and also share your profile with other registered JR members within mobile app.
                        </li>
                        <li>
                            JR's obligation is only to provide an interface to its registered members to search their prospect themselves without any assistance.
                        </li>
                        <li>
                            JR does not prohibit any JR member from sending interest to your profile or communicating to you based on their partner preference. But you are at the liberty to deny their interest or proceed further if you are interested.
                        </li>
                        <li>
                            JR cannot guarantee or assume responsibility for any specific results from the use of the data available from the JR
                        </li>
                    </ul>
                </span>

                <h2 class="subHedTerms">ROLE AND RESPONSIBILITY OF JR MEMBER</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            You, shall safeguard your profile information by creating a strong password during profile creation with combination of alphabets, both upper and lower case and numbers.
                        </li>
                        <li>
                            B. Any information / data required by JR for using the JR services shall be provided by the JR Member, as and when so sought by JR.
                        </li>
                        <li>
                            C. You are requested to verify the credentials of the prospect, exercise due care and caution regarding their profile information which includes marital status, educational qualifications, financial status, occupation, character, health status, etc. and satisfy yourself before making a choice of your match. JR shall not be liable for short coming due to any misrepresentations made by any of its JR members.
                        </li>
                        <li>
                            D. To get better search results, JR Members are expected to provide latest photograph which should not be more than 3 (three) months old. Providing old photographs/ photographs of others, inaccurate / false information shall be treated as violation of terms and conditions and JR shall retain their right under clause 2 (b) of this terms and conditions.
                        </li>
                        <li>
                            E. JR members are expected to disclose their health records during profile enrollment which includes any pre-existing illness, physical disability etc. Non - disclosure at the time of enrollment shall be treated as violation of the terms and conditions and JR shall retain their right under clause 2 (b) of this terms and conditions.
                        </li>
                    </ul>
                </span>


                <h2 class="subHedTerms">DISPUTE BETWEEN MEMBERS</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            The customer agrees and acknowledges that he/ she, in any case, Jiveshwar Sali Reshimgathi shall not responsible, for any dispute that has arisen, with other member/s.
                        </li>
                        <li>
                            The customer agrees and acknowledges that he is solely responsible for the acts and communications, done by him on the website/mobile app.
                        </li>
                        <li>
                            The customer agrees and acknowledges that Jiveshwar Sali Reshimgathi and the website are not acting as broker of any of the customer (prospect, match etc.)
                        </li>
                        <li>
                            Customer agrees and acknowledges that he/she does not want that Jiveshwar Sali Reshimgathi has any obligation to monitor such disputes. Customer also agrees and acknowledges that Jiveshwar Sali Reshimgathi shall not be made party in case of such disputes.
                        </li>
                        <li>
                            Jiveshwar Sali Reshimgathi has no obligation of whatsoever nature, to monitor such disputes and Jiveshwar Sali Reshimgathi shall not be added as party to the same.
                        </li>
                    </ul>
                </span>


                <h2 class="subHedTerms">LIMITED LIABILITY</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            JR or its Office bearers shall under no circumstances be liable or responsible to the JR member or his/her authorized Representative or Registrant or any third party for any direct, indirect, special, exemplary, incidental, or consequential damages of any character including, without limitation, damages resulting from the use of our Website/App/Third Party Website/ JR services.
                        </li>
                        <li>
                            JR informs you that the exchange of profile(s) through or by JR should not in any way be construed as a matrimonial offer and/or recommendation and / or advice or guarantee given to the JR member
                        </li>
                        <li>
                            Notwithstanding anything to the contrary contained herein, JR's liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to JR, for any specific JR paid package, and no further.
                        </li>
                        <li>
                            General : Once your paid membership expires, you cannot access the already viewed JR member(s) contact information.
                        </li>
                    </ul>
                </span>


                <h2 class="subHedTerms">DISCLAIMER</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            Your access to and use of the JR Services or any content is at your own risk. YOU UNDERSTAND AND AGREE THAT THE JR SERVICES ARE PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, JR DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. JR does not warrant that the Website/App, its servers, or e-mail sent from JR are free of viruses or other harmful components. JR will not be liable for any damages of any kind arising from the use of this JR Website/App, including, but not limited to direct, indirect, incidental, punitive, and consequential damages
                        </li>
                        <li>
                            JR does not give any implied or explicit guarantee or warranty of marriage or alliance by you choosing to register on our Website/App and using JR services (both paid and free).
                        </li>
                        <li>
                            Notwithstanding anything contrary contained anywhere, under no circumstances, JR shall be held responsible or liable whatsoever or howsoever, arising out of, relating to or connected with:
                        </li>
                        <li>
                            A. Any act or omission done by JR payment gateway/alliance partner etc.;
                        </li>
                        <li>
                            B. Any untrue or incorrect information submitted by you or on your behalf;
                        </li>
                        <li>
                            C. Any decision taken by you or on your behalf or any consequences thereof, based on any information provided by any other user (including suspension/deletion of the profile from JR);

                        </li>
                        <li>
                            D. Any unauthorized or illegal act done by any third party relating to or connected with any information submitted by you or on your behalf;

                        </li>
                        <li>
                            E. Any cyber crime attempted or committed by anyone and any incident of force-majeure or 'act of god'.
                        </li>
                        <li>
                            F. Any issue already stated in these terms and conditions including limitation of liability clause of these terms and conditions.
                        </li>
                        <li>
                            G. JR shall not be liable for the outcome of during any interaction in a meeting, call, sms, chat, email or social media posts at any point of time
                        </li>
                        <li>
                            H. Any issues relating to any technical malfunction of any telecommunication network, software, hardware failures, network congestion, denial of service, failure due to spamming or any combination of the above.
                        </li>
                        <li>
                            I. You expressly agree that your use of this Website/App is at your sole risk.
                        </li>
                    </ul>
                </span>

                <h2 class="subHedTerms">TERMINATION</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            A. JR in good faith reserve our right to terminate your JR membership or delete your profile at our sole discretion without any notice to you.
                        </li>
                        <li>
                            B. If member have provided incorrect information on our website/App or have committed any fraudulent/ unlawful/ illegal activities through the use of our website/App or have violated this Terms and conditions or have misappropriated the name, likeness, email address, contact no or other personally identifiable information of another JR member created a profile on our website /App by impersonation/ fake/ bogus/ false/ misrepresentation/ without consent of the person who's profile is being registered or use only part information including using photo of third parties without the permission of such third parties or act with other members/employees in indecent/improper manner,
                        </li>
                        <li>
                            C. If JR receives any complaint against you and consequently we will suspend/delete your profile in good faith, then we shall not be liable to you in any manner whatsoever, including for any loss, costs, expenses, or consequence, if any.
                        </li>
                        <li>
                            D. Upon termination / suspension of JR membership, your right to use or access the JR Website/App JR services will cease immediately. All provisions of this terms and conditions that by their nature should survive termination shall survive termination, including, without limitation, disclaimers, and limitations of liability. Termination of your access to and use of the JR Services shall not relieve you of any obligations arising or accruing prior to such termination.
                        </li>
                    </ul>
                </span>


                <h2 class="subHedTerms">MODE OF PAYMENT</h2>
                <span class="aboutPara">
                    Payment made by Net Banking ,Debit / Credit Card/ Paypal/ RTGS/ NEFT/ would be in accordance with the prevailing RBI guidelines. Activation of paid JR service shall be subject to realization of the said payment.
                </span>
                <span class="aboutPara">
                    JR uses third party payment gateways for collection payment through credit/debit card or other payment instruments.
                </span>


                <h2 class="subHedTerms">REFUND POLICY:</h2>
                <span class="aboutPara">
                    <ul style="list-style-type: disclosure-closed;">
                        <li>
                            The payments made by any JR member towards to membership fee or renewal fee are treated as non-refundable.
                        </li>
                        <li>
                            Payment once made for JR services cannot be assigned to any person/party or adjusted towards any other product or packages provided by the Company.
                        </li>
                    </ul>
                </span>

                <h2 class="subHedTerms">REFUND POLICY:</h2>
                <span class="aboutPara">
                    In the event of cancellation of any paid by member towards to membership for which payment has been successfully made and a confirmation number has been generated, and the services has not been concluded due to any avoidable / unavoidable reason(s) we must be notified of the same in writing by an email at jiveshwarsalireshimgathi@gmail.com.
                </span>


                <h2 class="subHedTerms">JURISDICTION AND APPLICABLE LAW</h2>
                <span class="aboutPara">
                    The customer registered with Jiveshwar Sali Reshimgathi and availing any of the services of Jiveshwar Sali Reshimgathi including website is deemed to have entered within the territorial jurisdiction of Nasik, Maharashtra, India only.
                </span>



              </div>
            </div>
  
  
        </div>
  
        <!-- <section class="image-section m-0 p-0 image-bg-2 section-main">
            <div class="container-fluid p-0 m-0">
                <div class="bg-overlay-dark"></div>
                <div class="content">
                    <h1>beautiful template <span class="colorTxt">in-two</span></h1>
                    <p>you will get a short idea about our daily working process</p>
                </div>
            </div>
  
        </section> -->
    </div>
  
    <app-bottom></app-bottom>
  </div>
  </div>
  
<div class="homeMain">
<div class="regiMain regiMainLogin" >
    <div id="samplecover-spin" *ngIf="logspinner"></div>
    
    <div class="loginOuter">
                <div class="loginLeft">
                    <div class="shreeGanesh"><img src="../../assets/images/ganesh.png"/></div>
                    <img src="assets/images/dimond.png" alt="img">
                    <p>Jiveshwar Sali</p>
                    <p class="reshimText">Reshimbandh</p>
                    <div class="arrowDiv"><i class="fa fa-lock"></i></div>
                </div>


                <div class="loginDiv" id="logShow">
                    <b class="homeLoginLnk"><a href="/" class="goHomeSt">Home</a></b>
                        <form name="frm1" id="frm1" [formGroup]="loginfrm" (ngSubmit)="createLoginPost(loginfrm.value)">
                    <div class="logInInner" >
                       <a href="/"> <img src="assets/images/br-logo1.png"></a>
                        <hr class="devider">
                        <h2 class="fs-title logiHed">Login Here</h2>
                        
                        <div class="logDiv">
                            <input type="text" name="loginemail" autocomplete="off" formControlName="loginemail" id="loginemailid" class="logInpFil" placeholder="Enter your Username / email address / Phone No." maxlength="30">
                            <div class="clear"></div>
                            <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && loginemail.invalid">
                                <div class="errorstTx" *ngIf="loginemail.errors.required">{{validTitle}}</div>
                            </div>
                
                            <input type="password" name="loginpass" autocomplete="off" formControlName="loginpass" id="loginpassid" class="logInpFil" placeholder="Enter your password" maxlength="30">
                            <div class="clear"></div>
                            <div class="animTest w3-animate-zoom" *ngIf="isSubmitted && loginemail.invalid">
                                <div class="errorstTx" *ngIf="!loginfrm.controls['loginpass'].valid && isSubmitted">{{validTitle}}</div>
                            </div>
                            <div class="clear"></div>
                           
                            <span class="logTe">
                                <a href="Javascript:void(0)" id="forGotLog" (click)="forgotfun()">Forgot Password?</a>
                            </span>
                        </div>

                       <input value="Login" class="loginBtmain" type="submit" >
                        <p class="needAc">Need an account? <a [routerLink]="['/registration']">Register now</a></p>
                    </div>
                </form>

                   
                </div>





            <div class="loginDiv" id="logHide">
                <b class="homeLoginLnk"><a href="/login" class="goHomeSt">Login</a></b>
                    <form name="frm2" id="frm2" [formGroup]="forgotfrm" (ngSubmit)="forgotLoginPost(forgotfrm.value)">
                 <div class="logInInner">
                       <a href="/"> <img src="assets/images/br-logo1.png"></a>
                        <hr class="devider">
                        <h2 class="fs-title logiHed">Forgot Password</h2>
                        <div class="forContent confirmOt">Enter your Profile ID / Registered Mobile No. / Registered Email ID to reset your password.You will receive to OTP to reset your password.</div>
                        <div class="logDiv">
                            <input type="text" name="useremail" formControlName="useremail" placeholder="Enter your Username / email address / Phone No." class="logInpFil">
                            <div class="clear"></div>
                            <div class="animTest w3-animate-zoom" *ngIf="isforgotSubmitted && useremail.invalid">
                                <div class="errorstTx" *ngIf="useremail.errors.required">{{validTitle}}</div>
                            </div>
                           
                        </div>
                        <input value="Submit" class="loginBtmain" type="submit" >
                        <p class="needAc">Need an account? <a [routerLink]="['/registration']">Register now</a></p>
                    </div>
                </form>
                </div>






                
    </div>
    
    </div>
</div>
    
    <!-- jQuery -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.min.js" type="text/javascript"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js" type="text/javascript"></script>
    
        <!-- <script src="js/jquery-1.11.1.min.js"></script>
        <script src="js/bootstrap.min.js"></script> -->

    

    

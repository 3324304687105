import { Component, OnInit } from '@angular/core';
import {HelpScreenService} from '../services/help-screen.service'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private help : HelpScreenService) { }

  logspinner:boolean; result : any;

  ngOnInit(): void {

    this.contactUs();

  }

  contactUs()
  {
    this.logspinner = true;
    let headers = new Headers({'Content-Type' : 'Application/json'})
    let body = new FormData
 
    body.append('CasteID', '3');  
    return this.help.contactUs(body,headers).subscribe(helpResponce =>{
      this.logspinner = false;
      this.result=helpResponce['data'];
      console.log("respoinse====>"+JSON.stringify(helpResponce))
      
    })
  }

}

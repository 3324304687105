import { LoginService } from '../services/login.service';
import {ViewmemberdetailsService} from '../services/viewmemberdetails.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router} from '@angular/router';
import * as $ from 'jquery';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
    validTitle:string="This is required";
    forgotval:number; logspinner:boolean; isSubmitted = false; isforgotSubmitted = false;
    constructor(private service:LoginService, private route:Router, private memberservice : ViewmemberdetailsService) 
    { 

    }
    
    loginfrm = new FormGroup({
         loginemail: new FormControl('',Validators.required),
         loginpass: new FormControl('',Validators.required)
        });
    
        forgotfrm = new FormGroup({
          useremail: new FormControl('',Validators.required)
        });

    get useremail(){ return this.forgotfrm.get('useremail');}
    get loginemail(){ return this.loginfrm.get('loginemail');}
    get loginpass(){ return this.loginfrm.get('loginpass');}
  
    ngOnInit(): void {


      if(localStorage.getItem("userid") && localStorage.getItem("MemberOTPVerified")=="1")
    {
      let link = ['/my-matches'];
      this.route.navigate(link);
    }


    //   if(localStorage.getItem("userid"))
    //  {
    //     this.logspinner = true;
    //     if(localStorage.getItem("MemberOTPVerified"))
    //     {
    //         if(localStorage.getItem("MemberOTPVerified")=="0")
    //         {
    //           this.logspinner = false;
    //           let link = ['/confirm-otp'];
    //           this.route.navigate(link);
    //         }
    //         else
    //         {
    //           this.logspinner = false;
    //           let link = ['/my-matches'];
    //           this.route.navigate(link);
    //         }

    //     }
    //     else
    //     {
    //       this.logspinner = false;
    //       let link = ['/confirm-otp'];
    //       this.route.navigate(link);
    //     }

    //  }
    
      
    }
    forgotfun()
    {
      $("#logShow").hide();
      $("#logHide").show();
    }
  
    loginfun()
    {
      $("#logShow").show();
      $("#logHide").hide();
    }
    createLoginPost = function(user1: HTMLInputElement)
    {
      this.isSubmitted = true;

      if(!this.loginfrm.valid) 
      {
        return false;
      } 
      else 
      {

      this.logspinner = true;
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemUserName', user1['loginemail']);
      body.append('MemPwd', user1['loginpass']);
      this.service.loginUrl(body,headers).subscribe(Response =>{
      this.loginresponsestatus=Response.status;
      if(Response.status==1) 
      {
        localStorage.clear();
        localStorage.setItem("userid", Response['data'].MemberID);
        localStorage.setItem("userprofileid", Response['data'].MemberProfileID);
        localStorage.setItem("webusername",user1['loginemail']);
        localStorage.setItem("webpassword",user1['loginpass']);

        if(Response['data'].MemberOTPVerified==0)
        {
          this.logspinner = false;
          let link = ['/confirm-otp'];
          this.route.navigate(link);
        }
        else
        { 
         
          localStorage.setItem("MemberOTPVerified", Response['data'].MemberOTPVerified);
          localStorage.setItem('UserMemberID',Response['data'].MemberID);
          localStorage.setItem('UserProfileID',Response['data'].MemberProfileID);
          localStorage.setItem('UserCasteCode',Response['data'].MemberCasteCode);
          localStorage.setItem('UserFullName',Response['data'].MemberFullName);
          localStorage.setItem('UserGender',Response['data'].MemberGender);
          localStorage.setItem('UserHeight',Response['data'].MemberHeight);
          localStorage.setItem('UserMartialStatus',Response['data'].MemberMartialStatus);
          localStorage.setItem('UserCasteID',Response['data'].MemberCasteID);
          localStorage.setItem('UserCasteName',Response['data'].MemberCasteName);
          localStorage.setItem('UserIsApprove',Response['data'].MemberIsApprove);
          localStorage.setItem('UserIsPaid',Response['data'].MemberIsPaid);
          localStorage.setItem('UserRegistrationDt',Response['data'].MemberRegistrationDt);
          localStorage.setItem('UserAge',Response['data'].MemberAge);
          localStorage.setItem('UserDOB',Response['data'].MemberDOB);
          localStorage.setItem('UserIsPhotoUpload',Response['data'].MemberIsPhotoUpload);
          localStorage.setItem('UserPhotoPath',Response['data'].MemberPhotoPath);
          localStorage.setItem('UserCountryID',Response['data'].MemberCountryID);
          localStorage.setItem('UserCountryName',Response['data'].MemberCountryName);
          localStorage.setItem('UserStateID',Response['data'].MemberStateID);
          localStorage.setItem('UserStateName',Response['data'].MemberStateName);
          localStorage.setItem('UserCityID',Response['data'].MemberCityID);
          localStorage.setItem('UserCityName',Response['data'].MemberCityName);
          localStorage.setItem('UserEduID',Response['data'].MemberEduID);
          localStorage.setItem('UserHeightsEducation',Response['data'].MemberHeightsEducation);
          localStorage.setItem('UserEduGroupID',Response['data'].MemberEduGroupID);
          localStorage.setItem('UserEductionGroupName',Response['data'].MemberEductionGroupName);
          localStorage.setItem('UserEmployedIn',Response['data'].MemberEmployedIn);
          localStorage.setItem('UserOccGroupID',Response['data'].MemberOccGroupID);
          localStorage.setItem('UserOccupGroupName',Response['data'].MemberOccupGroupName);
          localStorage.setItem('UserOccID',Response['data'].MemberOccID);
          localStorage.setItem('UserOccupation',Response['data'].MemberOccupation);
          localStorage.setItem('UserJobCountryName',Response['data'].MemberJobCountryName);
          localStorage.setItem('UserJobCity',Response['data'].MemberJobCity);
          localStorage.setItem('UserFamilyStatus',Response['data'].MemberFamilyStatus);
          localStorage.setItem('UserFamilyType',Response['data'].MemberFamilyType);
          localStorage.setItem('UserFamilyValue',Response['data'].MemberFamilyValue);
          localStorage.setItem('UsercasteView',Response['data'].MembercasteView);
          localStorage.setItem('UserSentIntCnt',Response['data'].MemberSentIntCnt);

          let headers = new Headers({ 'Content-Type': 'application/json' });
          let body = new FormData();
          body.append('CasteID', '3');

          this.service.appversionUrl(body,headers).subscribe(Result =>{
            console.log(Result)
          if(Result.status==1) 
          { 
            localStorage.setItem('UserAppCode',Result['data'][0].AppCode);
            localStorage.setItem('UserAppDiscountBannerFlag',Result['data'][0].AppDiscountBannerFlag);
            localStorage.setItem('UserAppInterestLimit',Result['data'][0].AppInterestLimit);
            localStorage.setItem('UserAppRateusFlag',Result['data'][0].AppRateusFlag);
            localStorage.setItem('UserAppURL',Result['data'][0].AppURL);
            localStorage.setItem('UserAppversion',Result['data'][0].Appversion); 
            localStorage.setItem('AppMaleLimit',Result['data'][0].AppMaleLimit); 
            localStorage.setItem('AppFemaleLimit',Result['data'][0].AppFemaleLimit); 
            localStorage.setItem('AppOneMonthFlag',Result['data'][0].AppOneMonthFlag); 
            localStorage.setItem('AppThreeMonthFlag',Result['data'][0].AppThreeMonthFlag); 
            localStorage.setItem('AppSixMonthFlag',Result['data'][0].AppSixMonthFlag); 
            localStorage.setItem('AppYearFlag',Result['data'][0].AppYearFlag); 

            

            if(localStorage.getItem('UserIsPaid')=='1')
            {
              let headers = new Headers({'Content-Type' : 'Application/json'})
              let body = new FormData

              body.append('VwName', localStorage.getItem('UsercasteView'));
              body.append('MemberID',localStorage.getItem('UserMemberID'));
              body.append('MembIsPaid', '1'); 
              body.append('LoggedUserid',localStorage.getItem('UserMemberID')); 

              this.memberservice.memberdetails(body,headers).subscribe(memberresponce =>{
                    localStorage.setItem('MemberSubExpiryDate',memberresponce['data'].MemberSubscriptionExpiredOn); 
                    this.logspinner = false;
                    let link = ['/my-matches'];
                    this.route.navigate(link);
              })

            }
            else
            {

              this.logspinner = false;
              let link = ['/my-matches'];
              this.route.navigate(link);

            }


            

          } 


        })
        
        
          
        }
          
      }
      else 
      {
          this.opensweetalertdng(Response.message);
          localStorage.removeItem("userid");
          localStorage.removeItem("userprofileid");
          this.logspinner = false;
      }

      },
      error => {
        Swal.fire('Login Failed', "Please enter valid username and password.", 'info');
        this.logspinner = false;
      })

    }

  
    }

    forgotLoginPost(userdata: HTMLInputElement)
    {

      this.isforgotSubmitted = true;

      if(!this.forgotfrm.valid)
      {
        return false;
      }
      else
      {

      this.logspinner = true;
      let headers = new Headers({ 'Content-Type': 'application/json' });
      let body = new FormData();
      body.append('MemUserName', userdata['useremail']);
      
      this.service.forgotUrl(body,headers).subscribe((Response) =>{
        console.log(Response);
       if(Response['status'] === 1) 
      {
          localStorage.setItem("userid", Response['data'].MemberID);
          localStorage.setItem("userprofileid", Response['data'].MemberProfileID);
          this.logspinner = false;
          // this.opensweetalert();
          let link = ['/confirm-otp'];
          this.route.navigate(link);
      } 
      else 
      {
          this.opensweetalertforgot(Response['message']);
          localStorage.removeItem("userid");
          localStorage.removeItem("userprofileid");
          this.logspinner = false;
      }

      })

    }


    }


    opensweetalert()
    {
      Swal.fire({
          text: 'Login Successfully',
          icon: 'success'
        });
    }
    opensweetalertdng(message)
    {
     Swal.fire('Login Failed', message, 'error')
    }

    opensweetalertforgot(message)
    {
     Swal.fire('Forgot Password Failed', message, 'error')
    }
    
    opensweetalertcst(){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this imaginary file!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
        
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
        }
      })
    }
  
  }

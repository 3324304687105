<div class="homeMain">
<div class="regiMain regiMainLogin">
    <div id="samplecover-spin" *ngIf="logspinner"></div>
    <div class="loginOuter">
                <div class="loginLeft">
                    <div class="shreeGanesh"><img src="../../assets/images/ganesh.png"/></div>
                    <img src="assets/images/dimond.png" alt="img">
                    <p>Jiveshwar Sali</p>
                    <p class="reshimText">Reshimbandh</p>
                    <div class="arrowDiv"><i class="fa fa-lock"></i></div>
                </div>


                <div class="loginDiv" id="logShow">
                    <b class="homeLoginLnk"><a href="/login" class="goHomeSt">Login</a><span>/</span><a href="/" class="goHomeSt">Home</a></b>
                        <form name="frm1" id="frm1" [formGroup]="loginfrm" (ngSubmit)="createLoginPost(loginfrm.value)">
                    <div class="logInInner" >
                       <a href="/"> <img src="assets/images/br-logo1.png"></a>
                        <hr class="devider">

                        
                        <div class="logDiv">
                        <div *ngIf="isregister">Your registration has been done successfully. Your profile ID {{userProfileID}}</div>
                            <label class="custom-file-label confirmOt" for="inputGroupFile01">We have sent you a One Time Password on your Phone and Email.</label>
                            <input type="text" name="loginemail" autocomplete="off" formControlName="loginemail" id="loginemailid" class="logInpFil" placeholder="Enter your OTP" maxlength="4">
                            <div class="clear"></div>
                            <div class="animTest w3-animate-zoom" *ngIf="loginemail.touched && loginemail.invalid">
                                <div *ngIf="loginemail.errors.required">{{validTitle}}</div>
                                <div class="animTest w3-animate-zoom" *ngIf="loginemail.errors.pattern">Please enter valid OTP</div>
                            </div>
                
        
                        </div>

                       <input value="OTP Verify" class="loginBtmain" type="submit">
                        
                    </div>
                </form>                   
                </div>

                
    </div>
    
    </div>
</div>
    
    <!-- jQuery -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.min.js" type="text/javascript"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js" type="text/javascript"></script>
    
        <!-- <script src="js/jquery-1.11.1.min.js"></script>
        <script src="js/bootstrap.min.js"></script> -->

    

    
